@import url(//fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900);

*, *::after, *::before {
  box-sizing: border-box;
}

html {
  font-size: 16px;

  @media (min-width: 1000px) {
    font-size: calc((1vw + 1vh) * 0.5);
  }
}

body {
  margin: 0;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #46237A;
}
