.App {
  text-align: center;
}

.wrap {
  display: flex;
  width: 100%;
  align-items: center;
  min-height: 100vh;
}

main {
  position: relative;
  z-index: 2;
  flex: 1;
  max-width: 100%;
  padding: 2rem 2rem 5% 2rem;
}

p {
  font-size: 5.5rem;
  font-weight: 900;
  color: #fff;
  max-width: 65rem;
  margin-left: auto;
  margin-right: auto;
  display: block;

  @media (max-width: 1000px) {
    font-size: 2rem;
  }
}

.button {
  padding: 1rem 3rem;
  border: 2px solid #3ddc97;
  border-radius: 3.125rem;
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-size: 1rem;
  display: inline-block;
}

#circles {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  filter: blur(200px);

  svg {
    overflow: visible;
    object-fit: cover;
  }
}
